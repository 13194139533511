const ApplicationSettings = `
  queryApplicationSettingsContents{
    flatData{
      specialAnnouncement
      meta{
        defaultImage
        facebookAppId
        defaultimage
        sitename
        siteurl
      }
    }
  }
`

export const BoardLinksFragment = `
flatData {
  title
  headline
  boardLinks {
    link
    title
  }
  regionLinks{
    title
    link
  }
}
`

export const PageBaseFragment = `
  metaDescription
  metaKeywords
  noIndex
  url
  name
  urlCanonical
  metaImage{
    fileName
  }
`

const LinkedItem = `
  linkedItem{
    ... on BasePage{
      flatData{
        url
      }
    }
  }
`

export const StatFeatureFragment = `
  fragment StatFeatureFragment on StatFeature{
    id
    flatData{
      headline
      subheadline
      anchor
      stats{
        flatData{
          label
          statOrNumber
          subscript
          color{
            flatData{
              shortName
            }
          }
        }
      }
    }
  }
`
export const TombstoneFeatureFragment = `
  fragment ContentFeatureTombstonesFragment on ContentFeatureTombstones{
    id
    flatData{
      headline
      subheadline
      anchor
      callsToAction{
        flatData{
          linkUrl
          text
          linkedItem{
            flatData{
              name
              url
            }
          }
        }
      }
      tombstones{
        flatData{
          amount
          color{
            flatData{
              shortName
            }
          }
          products{
            flatData{
              name
            }
          }
          productGroups{
            flatData{
              name
            }
          }
          solutions{
            flatData{
              name
            }
          }
          industry{
            flatData{
              name
            }
          }
          industryDetail{
            flatData{
              name
            }
          }
          customerLocation{
            flatData{
              name
            }
          }
        }
      }
    }
  }
`
export const ImageGridFragment = `
  fragment ImageGridFragment on ImageGrid{
    id
    flatData{
      headline
      subheadline
      anchor
      images {
        ... on Image{
          flatData{
            isSquare
            image{
              fileName
              slug
              id
              metadata
            }
          }
        }
      }
    }
  }
`

export const HeroFragment = `
  fragment HeroFragment on Hero {
    id
    flatData{
      headline
      subheadline
      description
      width{
        flatData{
          shortName
        }
      }
      ctaWidgets{
        flatData{
          text
          email
          hollow
          secondary
          tertiary
          marketoId
          marketoConfirmation
          linkUrl
          ${LinkedItem}
        }
      }
    }
  }
`
export const TestimonialContentFeatureFragment = `
  fragment TestimonialContentFeatureFragment on TestimonialContentFeature{
    flatData{
      headline
      subheadline
      anchor
      showIcon
      testimonials{
        flatData{
          quoteFull
          quoteShort
          quoteAttribution{
            flatData{
              firstName
              lastName
              title
              company{
                flatData{
                  name
                }
              }
            }
          }
          color {
            flatData {
              shortName
              displayName
            }
          }
        }
      }
    }
  }
`
export const CardsContentFeatureFragment = `
  fragment CardsContentFeatureFragment on CardsContentFeature{
    flatData{
      headline
      subheadline
      anchor
      cta{
        flatData{
          text
          linkUrl
          hollow
          secondary
          isAnchor
          linkedItem{
            flatData{
              url
            }
          }
        }
      }
      cards{
        flatData{
          headline
          subheadline
          cardType
          linkUrl
          linkText
          cardMapEmbed
          targetPage{
            __typename
            ... on ServiceSuite{
              flatData{
                pageBase{
                  flatData{
                    url
                  }
                }
              }
            }
            ... on Solution{
              flatData{
                pageBase{
                  flatData{
                    url
                  }
                }
              }
            }
            ... on Product{
              flatData{
                pageBase{
                  flatData{
                    url
                  }
                }
              }
            }
            ... on BasePage{
              flatData{
                url
              }
            }
          }
          image{
            fileName
            id
            slug
            metadata
          }
          iconColor{
            flatData{
              shortName
            }
          }
          icon{
            id
            version
            flatData{
              svg
              name
              color{
                flatData{
                  shortName
                }
              }
            }
          }
        }
      }
    }
  }
`
export const CalltoactionFragment = `
  fragment CalltoactionFragment on Calltoaction{
    id
    version
    flatData{
      headline
      body
      subheadline
      anchor
      type
      imagePosition
      color {
        flatData{
          shortName
        }
      }
      image{
        flatData{
          isSquare
          image{
            fileName
            slug
            id
            metadata
          }
        }
      }
      ctaWidgets{
        flatData{
          text
          email
          hollow
          secondary
          marketoId
          marketoConfirmation
          linkUrl
          isAnchor
          ${LinkedItem}
        }
      }
    }
  }
`
export const ContentFeatureContentBlocksFragment = `
  fragment ContentFeatureContentBlocksFragment on ContentFeatureContentBlocks{
    flatData{
      headline
      subheadline
      anchor
      splitRatio{
        flatData{
          name
          shortName
        }
      }
      width{
        flatData{
          shortName
        }
      }
      leftContentBlock{
        __typename
        ... on Image{
          flatData{
            isSquare
            image{
              fileName
              slug
              id
              metadata
            }
          }
        }
        ... on TextBlock{
          flatData{
            headline
            subheadline
            body
            verticalAlignment{
              flatData{
                name
              }
            }
            ctas{
              flatData{
                text
                linkUrl
                hollow
                secondary
                marketoId
                marketoConfirmation
                isAnchor
                linkedItem{
                  flatData{
                    url
                  }
                }
              }
            }
          }
        }
      }
      rightContentBlock{
        __typename
        ... on Image{
          flatData{
            isSquare
            image{
              fileName
              slug
              id
              metadata
            }
          }
        }
        ... on TextBlock{
          flatData{
            headline
            subheadline
            body
            verticalAlignment{
              flatData{
                name
              }
            }
            ctas{
              flatData{
                text
                linkUrl
                hollow
                secondary
                marketoId
                marketoConfirmation
                isAnchor
                linkedItem{
                  flatData{
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export const SupportingContent = `
  __typename
  ... on TestimonialContentFeature{
    ...TestimonialContentFeatureFragment
  }
  ... on CardsContentFeature{
    ...CardsContentFeatureFragment
  }
  ... on ContentFeaturePeople{
    ...ContentFeaturePeopleFragment
  }
  ... on Calltoaction{
    ...CalltoactionFragment
  }
  ... on ContentFeatureContentBlocks{
    ...ContentFeatureContentBlocksFragment
  }
  ... on ContentFeatureTombstones {
    ...ContentFeatureTombstonesFragment
  }
  ... on ImageGrid{
    ...ImageGridFragment
  }
  ... on StatFeature{
    ...StatFeatureFragment
  }
  ... on Headline{
    ...HeadlineFragment
  }
  ... on BoardLinks{
    ${BoardLinksFragment}
  }
  ... on ContentFeatureIconTextList{
    flatData{
      headline
      subheadline
      anchor
      textIconWidgets{
        flatData{
          text
          title
          externalLink
          linkText
          marketoId
          marketoConfirmation
          icon{
            icon{
              ... on Icon{
                flatData{
                  svg
                }
              }
            }
            color{
              flatData{
                shortName
              }
            }
          }
          targetItem{
            flatData{
              url
            }
          }
        }
      }
      firstColumnWidgets
      pageSplitRatio{
        flatData{
          shortName
        }
      }
    }
  }
`

export const ServiceSuiteSolutionCardFragment = `
  flatData{
    name
    shortDescription
    taglineOrSubhead
    pageBase{
      flatData{
        url
        icon{
          color{
            flatData{
              shortName
            }
          }
          icon{
            flatData{
              svg
            }
          }
        }
      }
    }
  }
`

export const SolutionCardFragment = `
  headline
  subheadline
  linkText
  childSolutions{
    flatData{
      name
      pageBase{
        flatData{
          url
        }
      }
    }
  }
  solution {
    flatData {
      pageBase {
        flatData {
          url
          icon {
            color {
              flatData {
                shortName
              }
            }
            icon {
              flatData {
                svg
              }
            }
          }
        }
      }
    }
  }
`

export const ResourcesFragment = `
    flatData{
      title
    }
`

export const ResourcesWithHeadlineFragment = `
    headline
    subheadline
    cta{
      flatData{
        text
        linkUrl
        linkedItem{
          flatData{
            url
            name
          }
        }
      }
    }
    resource{
      flatData{
        title
        shortDescription
        taglineOrSubhead
        cardMapEmbed
        type{
          flatData{
            name
          }
        }
        pageBase{
          flatData{
            url
          }
        }
        mainImage{
          id
          slug
          fileName
          metadata
        }
        featuredImage{
          id
          slug
          fileName
          metadata
        }
        categories{
          flatData{
            name
          }
        }
        topics{
          flatData{
            name
          }
        }
      }
    }
`

export const HeadlineFragment = `
  fragment HeadlineFragment on Headline{
    flatData{
      headline
      subheadline
      cta{
        flatData{
          text
          linkUrl
          linkedItem{
            flatData{
              url
              name
            }
          }
        }
      }
    }
  }
`

export const PersonFragment = `
  person {
    __typename
    ... on Person {
      flatData {
        firstName
        lastName
        shortBio
        fullBio
        title
        leadership
        seniorLeadership
        board
        company{
          flatData{
            name
          }
        }
        bioImage {
          image {
            flatData {
              image {
                fileName
                id
                slug
                metadata
              }
            }
          }
        }
        pageBase {
          flatData {
            url
          }
        }
        associatedLocations{
          id
          flatData{
            name
            pageBase {
              flatData {
                url
              }
            }
          }
        }
        solutionsExpertise {
          id
        }
      }
    }
    ... on ExternalPerson {
      flatData {
        firstName
        lastName
        title
        shortBio
        bioImage {
          cutout
          image {
            flatData {
              image {
                fileName
                id
                slug
                metadata
              }
            }
          }
        }
        company{
          flatData{
            name
          }
        }
      }
    }
  }
`

export const TopNav = `
  queryTopNavContents{
    flatData{
      links{
        page{
          flatData{
            name
            url
          }
        }
        linkTitle
        alternateLink
      }
    }
  }
`

export const MainNav = `
  queryMainNavContents{
    flatData{
      links{
        menuSections{
          flatData{
            headline
            description
            links{
              pageTitle
              type
              targetPage{
                flatData{
                  name
                  url
                }
              }
            }
          }
        }
        page{
          flatData{
            name
            url
          }
        }
        linkTitle
        alternateLink
      }
    }
  }
`

export const Topics = `
  queryContentTopicContents{
    flatData{
      name
      slug
    }
  }
`

export const AllPageFragments = `
  query{
    ${MainNav}
    ${TopNav}
    ${Topics}
  }
`
export const StatesListFragment = `
  queryStateContents{
    flatData {
      abbreviation
      name
    }
  }
`
export const PersonCategoryOrType = `
  __typename
`
export const ContentFeaturePeopleFragment = `
  fragment ContentFeaturePeopleFragment on ContentFeaturePeople{
    flatData{
      headline
      level
      subheadline
      anchor
      people{
        ${PersonFragment}
      }
    }
  }
`
export const ResourceCardFragment = `
  flatData{
    title
    shortDescription
    featured
    createdAt
    cardMapEmbed
    pageBase{
      flatData{
        url
      }
    }
    author{
      flatData{
        firstName
        lastName
      }
    }
    type{
      id
      flatData{
        name
      }
    }
    mainImage{
      id
      slug
      fileName
      metadata
    }
    featuredImage{
      id
      slug
      fileName
      metadata
    }
    categories{
      flatData{
        name
        slug
      }
    }
    topics{
      flatData{
        name
        slug
      }
    }
  }
`
